import React, { useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Button, Modal } from "flowbite-react";
import OTPInput from '@src/components/model/OTPInput'


const SubscribesModel = (props) => {
  const { locale } = useRouter();
  const {
    metadata,
    openModal,
    userUpload,
    onSubscribeClick,
    isSubscriberLoading,
    setEmailText,
    emailText,
    otpEnterStatus,
    setOpenModal,
    otpText,
    setOtpText,
    setOtpEnterStatus,
    lang
  } = props;

  const [inputErr, setInputErr] = useState("")
  const onHandleChange = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e.target.value.trim())) {
      setInputErr("")
    }else{
      setInputErr("Please enter valid email address")
    }
    setEmailText(e.target.value.trim());
    
  };

  
  const handleOTPComplete = (otp) => {
    setOtpText(otp)
  };
  const onClose = async() => {
    setOpenModal(false)
    setEmailText("")
    setOtpText("")
    setOtpEnterStatus(false)
  }
  return (
    <div>
      <Modal
        show={openModal}
        size="md"
        onClose={onClose}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="subscribePopupBoday text-center">
            <div className="mx-auto logo-upload-box h-16 w-16  rounded-full bg-gray-200 relative flex justify-center align-middle">
              <Image
                src={metadata?.logo_url || userUpload}
                alt=""
                width={64}
                height={64}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <h2 className="font-medium text-black mb-1 mt-2.5">
              {" "}
              {metadata?.site_title || ""}
            </h2>
            <p className=" text-sm">
              {lang == 'ar' ? "تلق الأخبار الأخيرة من" : "Receive the latest updates from"}
              <span className="font-medium">
                {" "}
                {metadata?.site_title || ""}
              </span>{" "}
              {lang == "ar" ? "مباشرة إلى صندوق الوارد الخاص بك." : "straight to your email inbox."}
            </p>
            <div className={`${otpEnterStatus ? "otpSubcription inputSubcription flex mt-6" : "inputSubcription flex mt-6"}`}>
              {otpEnterStatus ? (
                <OTPInput length={6} onComplete={handleOTPComplete} />
              ) : (
                <input
                  type="text" // Use "text" type for both first name and last name
                  name="emailText"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md 
                            focus:ring-0 focus:border-project-color-sky-400 block w-full p-2.5 h-10"
                  placeholder={lang == 'ar' ? "أدخل عنوان بريدك الإلكتروني" : "Enter your email address"}
                  value={emailText}
                  onChange={onHandleChange}
                />
              )}
             

              <Button
                className=" bg-mor-blue-500 hover:!bg-mor-blue-700 rounded-md text-white px-3 ml-2 text-sm !opacity-100"
                type="button"
                onClick={onSubscribeClick}
                disabled={otpEnterStatus && !otpText || !emailText || inputErr ? true : false}
              >
                { isSubscriberLoading ? "Loading..." : otpEnterStatus ? `${lang == 'ar' ? "تحقق من OTP" : "Verify OTP"}` : `${lang == 'ar' ? "اشترك" : "Subscribe"}`}
              </Button>
            </div>
            <span>{inputErr}</span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubscribesModel;
