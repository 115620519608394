import React from "react";
const DefaultButton = (props) => {
  const { buttonShape, buttonShadow, buttonColorFill, buttonColorStroke, font, fontTextAlign, fontColor} = props;
  return (
    <div className="social-with-text-box">
      <a className={`hover:scale-105 duration-200 block border border-gray-200 rounded-lg py-2.5 px-3 font-medium mb-3 text-sm relative ${buttonShape} ${buttonShadow} ${font}`}
        href="www.shopify.com"
        style={{
          backgroundColor: buttonColorFill,
          borderColor: buttonColorStroke,
          fontColor : fontColor,
          textAlign: fontTextAlign,
          ...(fontTextAlign === "left" && {
            paddingLeft: "34px",
          }),
        }}
      >
        <svg
          className="mr-2 absolute left-2 rtl:left-auto rtl:right-2"
          width="24"
          height="24"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5979"
            y="0.45874"
            width="30.7172"
            height="30.7172"
            rx="15.3586"
            fill="url(#paint0_radial_4807_2384)"
          />
          <g clip-path="url(#clip0_4807_2384)">
            <path
              d="M22.8519 8.52095C22.8375 8.41344 22.7454 8.35394 22.6693 8.34739C22.1082 8.30426 21.547 8.26148 20.9859 8.21904C20.9859 8.21904 19.8694 7.08539 19.7469 6.95992C19.6243 6.83451 19.3849 6.87266 19.2919 6.90064C19.2782 6.90476 19.048 6.97744 18.6672 7.09799C18.2942 6.00035 17.6361 4.99167 16.4782 4.99167C16.4462 4.99167 16.4133 4.99299 16.3804 4.99491C16.0511 4.54948 15.6432 4.35596 15.2909 4.35596C12.5936 4.35596 11.305 7.80476 10.901 9.55732C9.85292 9.8895 9.10836 10.1257 9.01326 10.1562C8.42824 10.3439 8.40974 10.3628 8.33292 10.9266C8.27511 11.3535 6.74438 23.4616 6.74438 23.4616L18.6721 25.7474L25.135 24.3174C25.135 24.3174 22.8662 8.62846 22.8519 8.52095ZM18.0079 7.30653L16.9986 7.62604C16.999 7.55328 16.9994 7.48171 16.9994 7.40343C16.9994 6.72126 16.9068 6.172 16.7583 5.73658C17.3549 5.81316 17.7522 6.50749 18.0079 7.30653ZM16.0182 5.87185C16.184 6.29696 16.2919 6.90704 16.2919 7.73031C16.2919 7.77243 16.2915 7.81094 16.2912 7.8499C15.6348 8.05785 14.9216 8.28362 14.2067 8.51013C14.6081 6.92575 15.3604 6.16051 16.0182 5.87185ZM15.2168 5.09594C15.3332 5.09594 15.4505 5.13636 15.5627 5.21538C14.6983 5.63143 13.7717 6.67928 13.3805 8.77183L11.7328 9.29377C12.1911 7.69769 13.2795 5.09594 15.2168 5.09594Z"
              fill="#95BF46"
            />
            <path
              d="M22.6687 8.34743C22.1076 8.3043 21.5464 8.26152 20.9853 8.21908C20.9853 8.21908 19.8689 7.08543 19.7463 6.95996C19.7005 6.91327 19.6386 6.88934 19.574 6.87903L18.6721 25.7473L25.1344 24.3174C25.1344 24.3174 22.8656 8.6285 22.8513 8.52099C22.8369 8.41348 22.7448 8.35398 22.6687 8.34743Z"
              fill="#5E8E3E"
            />
            <path
              d="M16.4781 11.9999L15.6812 14.4244C15.6812 14.4244 14.983 14.0433 14.1271 14.0433C12.8724 14.0433 12.8093 14.8487 12.8093 15.0516C12.8093 16.159 15.6314 16.5833 15.6314 19.1771C15.6314 21.2178 14.366 22.5318 12.6598 22.5318C10.6123 22.5318 9.56519 21.2284 9.56519 21.2284L10.1134 19.3757C10.1134 19.3757 11.1897 20.3209 12.0979 20.3209C12.6914 20.3209 12.9328 19.843 12.9328 19.4938C12.9328 18.0493 10.6174 17.9849 10.6174 15.6113C10.6174 13.6136 12.0193 11.6804 14.8491 11.6804C15.9394 11.6804 16.4781 11.9999 16.4781 11.9999Z"
              fill="white"
            />
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_4807_2384"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(9.81491 9.36852) rotate(47.7464) scale(29.4708)"
            >
              <stop stop-color="white" />
              <stop offset="0.810915" stop-color="#E4ECEF" />
            </radialGradient>
            <clipPath id="clip0_4807_2384">
              <rect
                width="18.4303"
                height="21.5021"
                fill="white"
                transform="translate(6.74146 4.29858)"
              />
            </clipPath>
          </defs>
        </svg>
        <div className="px-3 py-1">My Shop</div>
      </a>
    </div>
  );
};
export default DefaultButton;
