// OTPInput.js
import React, { useState, useRef } from 'react';

const OTPInput = ({ length, onComplete }) => {
  const [otp, setOTP] = useState(Array(length).fill(''));
  const inputRefs = useRef([...Array(length)].map(() => React.createRef()));


  const handleInputChange = (e, index) => {
    const value = e.target.value;
  
    if (isNaN(value)) {
      return;
    }
  
    const newOTP = [...otp];
    newOTP[index] = value;
  
    setOTP(newOTP);
  
    // Move focus to the next box if the value is not empty and the index is less than the last index.
    if (value !== '' && index < length - 1) {
      inputRefs.current[index + 1].current.focus();
    }
  
    // Perform further actions or validation if needed
  
    // If the OTP is complete (no empty values), you can call the onComplete function.
    if (!newOTP.includes('')) {
      onComplete(newOTP.join(''));
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      // inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className='otpInputContainer'>
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={inputRefs.current[index]}
          className="otpInput"
        />
      ))}
    </div>
  );
};

export default OTPInput;
