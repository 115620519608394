import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import userService from "@src/services/userService";
import Link from "next/link";
import { Button, Modal } from "flowbite-react";
import * as sweetAlert from "../../utils/sweetAlert";
import treepixelRoundLogo from "@src/static/img/treepixel-round-logo.svg";
import Papa from "papaparse";

const PreviewContactDetailsModal = (props) => {
  const {
    showPreviewContactDetailsModal,
    handleCloseModal,
    contactData,
    contact_logo,
    id,
    language,
  } = props;

  const [contactInfo, setContactInfo] = useState({
    name: contactData?.user_card_details || "-",
    tel: contactData?.phone_no.map((item) => `${item?.mobile} `) || "-",
    email: contactData?.email.map((item) => item?.email) || "-",
    address: `${contactData?.address_1 ? `${contactData?.address_1},` : ""} ${
      contactData?.address_2 ? `${contactData?.address_2},` : ""
    } ${contactData?.city_name ? `${contactData?.city_name},` : ""} ${
      contactData?.region_name ? `${contactData?.region_name},` : ""
    } ${contactData?.country_name ? `${contactData?.country_name},` : ""}`,
    googleMapLink: contactData?.google_map_link || "",
    profession: contactData?.profession,
    position: contactData?.position || "",
    openingDays: contactData?.opening_days || "",
    openingHours: contactData?.opening_hours || "",
    date: contactData?.updated_at || "",
  });
  //contact card csv download
  const contactJsonData = [
    {
      Name: contactData?.user_card_details || "-",
      PhoneNumber:
        contactData?.phone_no.map((item, index) => `${item?.mobile} `) || "-",
      Email: contactData?.email.map((item, index) => item?.email) || "-",
      Address: `${contactData?.address_1 ? `${contactData?.address_1},` : ""} ${
        contactData?.address_2 ? `${contactData?.address_2},` : ""
      } ${contactData?.city_name ? `${contactData?.city_name},` : ""} ${
        contactData?.region_name ? `${contactData?.region_name},` : ""
      } ${contactData?.country_name ? `${contactData?.country_name},` : ""}`,
      GoogleMapLink: contactData?.google_map_link || "",
      Profession: contactData?.profession || "",
      Position: contactData?.position || "",
      OpeningDays: contactData?.opening_days || "",
      OpeningHours: contactData?.opening_hours || "",
      SiteStatus: contactData?.status || "",
      Date: contactData?.updated_at || "",
    },
  ];
  ////////////////////////////////////////////////////////////////////////////////
  const convertToCSV = (jsonArray) => {
    const csv = Papa.unparse(jsonArray);
    return csv;
  };

  const handleCSVDownload = () => {
    const {
      name,
      tel,
      address,
      email,
      googleMapLink,
      profession,
      position,
      openingDays,
      openingHours,
      date,
    } = {
      name: contactData?.user_card_details || "-",
      tel: contactData?.phone_no.map((item) => `${item?.mobile} `) || "-",
      email: contactData?.email.map((item) => item?.email) || "-",
      address: `${contactData?.address_1 ? `${contactData?.address_1},` : ""} ${
        contactData?.address_2 ? `${contactData?.address_2},` : ""
      } ${contactData?.city_name ? `${contactData?.city_name},` : ""} ${
        contactData?.region_name ? `${contactData?.region_name},` : ""
      } ${contactData?.country_name ? `${contactData?.country_name},` : ""}`,
      googleMapLink: contactData?.google_map_link || "",
      profession: contactData?.profession,
      position: contactData?.position || "",
      openingDays: contactData?.opening_days || "",
      openingHours: contactData?.opening_hours || "",
      date: contactData?.updated_at || "",
    };

    const vcard = `BEGIN:VCARD
VERSION:3.0
N:${name}
FN:${name}
TEL;TYPE=WORK,VOICE:${tel}
ADR;TYPE=WORK,PREF:;;${address}
EMAIL:${email}
URL:${googleMapLink}
ORG:${profession}
TITLE:${position}
X-OPENINGDAYS:${openingDays}
X-OPENINGHOURS:${openingHours}
REV:${date}
END:VCARD`;

    // Create a Blob containing the vCard content
    const blob = new Blob([vcard], { type: "text/vcard" });

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${name}.vcf`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    //////////////////////////////////////// using this download CSV file/////////////////////////

    // const csvData = convertToCSV(contactJsonData);
    // const blob1 = new Blob([csvData], { type: "text/csv" });
    // const url1 = URL.createObjectURL(blob1);
    // const link = document.createElement("a");
    // link.href = url1;
    // link.download = "Contact-info.csv";
    // link.click();
    // URL.revokeObjectURL(url1);
  };
  return (
    <div>
      <Modal
        className="ContactInfoPop"
        show={showPreviewContactDetailsModal}
        size="sm"
        popup
        onClose={() => {
          handleCloseModal();
        }}
      >
        <Modal.Header className="border-none py-3 px-5 crossButtonContact">
          <div className="">
            <h2 className="font-semibold text-sm">
              {language == "ar" ? "معلومات الاتصال" : "Contact info"}
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-5 px-5 pb-0">
          <div className="px-4">
            <div className="card-title-img-box flex items-center relative pl-10 contactPrevirewTitle">
              <div className="h-10 w-10 absolute left-0">
                <Image
                  className="inline-block w-full h-full object-cover"
                  src={contact_logo}
                  alt=""
                  width={42}
                  height={42}
                />
              </div>
              <div className="mx-3">
                <h2 className="text-sm font-semibold mb-1">
                  {`${contactData?.first_name} ${contactData?.last_name}` ||
                    "-"}
                </h2>
                <p className="text-xs text-black-color-400">
                  {contactData?.profession} • {contactData?.position}
                </p>
              </div>
            </div>
            <div className="w-32 h-1 border-t border-black-color-200 mx-auto my-5"></div>
            <div className="">
              {contactData && contactData?.email
                ? contactData?.email.map((item, index) => (
                    <div
                      className="flex items-center py-2 pt-0 gap-2"
                      key={index}
                    >
                      <svg
                        width="16"
                        height="16"
                        className=""
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1804_42611)">
                          <path
                            d="M10.6668 5.33333V8.66666C10.6668 9.19709 10.8776 9.7058 11.2526 10.0809C11.6277 10.4559 12.1364 10.6667 12.6668 10.6667C13.1973 10.6667 13.706 10.4559 14.0811 10.0809C14.4561 9.7058 14.6668 9.19709 14.6668 8.66666V7.99999C14.6667 6.49535 14.1577 5.03498 13.2224 3.85635C12.287 2.67772 10.9805 1.85014 9.51526 1.50819C8.04999 1.16624 6.51213 1.33002 5.15173 1.9729C3.79134 2.61579 2.68843 3.69996 2.02234 5.04914C1.35625 6.39832 1.16615 7.93315 1.48295 9.40407C1.79975 10.875 2.60482 12.1955 3.76726 13.1508C4.92969 14.1062 6.38112 14.6402 7.88555 14.6661C9.38997 14.692 10.8589 14.2082 12.0535 13.2933M10.6668 7.99999C10.6668 9.47275 9.47293 10.6667 8.00017 10.6667C6.52741 10.6667 5.3335 9.47275 5.3335 7.99999C5.3335 6.52723 6.52741 5.33333 8.00017 5.33333C9.47293 5.33333 10.6668 6.52723 10.6668 7.99999Z"
                            stroke="#333B4A"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1804_42611">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <Link target="_blank" href={`mailto:${item?.email}`}>
                        <p className="dfsf text-xs font-medium">
                          {item?.email || "-"}
                        </p>
                      </Link>
                    </div>
                  ))
                : null}

              {contactData && contactData?.phone_no
                ? contactData?.phone_no.map((item, index) => (
                    <div key={index}>
                      <div className="flex items-center py-2 gap-2">
                        <svg
                          width="16"
                          height="16"
                          className=""
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.58685 5.90211C6.05085 6.86853 6.68338 7.77429 7.48443 8.57534C8.28548 9.37639 9.19124 10.0089 10.1577 10.4729C10.2408 10.5128 10.2823 10.5328 10.3349 10.5481C10.5218 10.6026 10.7513 10.5635 10.9096 10.4501C10.9542 10.4182 10.9923 10.3801 11.0685 10.3039C11.3016 10.0708 11.4181 9.95431 11.5353 9.87812C11.9772 9.59079 12.5469 9.59079 12.9889 9.87812C13.106 9.95431 13.2226 10.0708 13.4556 10.3039L13.5856 10.4338C13.9399 10.7881 14.117 10.9653 14.2132 11.1555C14.4046 11.5339 14.4046 11.9807 14.2132 12.3591C14.117 12.5494 13.9399 12.7265 13.5856 13.0808L13.4805 13.1859C13.1274 13.539 12.9508 13.7155 12.7108 13.8504C12.4445 14 12.0308 14.1075 11.7253 14.1066C11.45 14.1058 11.2619 14.0524 10.8856 13.9456C8.86333 13.3716 6.95509 12.2886 5.36311 10.6967C3.77112 9.10467 2.68814 7.19643 2.11416 5.17417C2.00735 4.79787 1.95395 4.60972 1.95313 4.33442C1.95222 4.02894 2.0598 3.61528 2.20941 3.34894C2.34424 3.10892 2.52078 2.93238 2.87386 2.5793L2.97895 2.47421C3.33325 2.11992 3.5104 1.94277 3.70065 1.84654C4.07903 1.65516 4.52587 1.65516 4.90424 1.84654C5.0945 1.94277 5.27164 2.11991 5.62594 2.47421L5.75585 2.60412C5.98892 2.83719 6.10546 2.95373 6.18165 3.07091C6.46898 3.51284 6.46898 4.08256 6.18165 4.52449C6.10546 4.64167 5.98892 4.75821 5.75585 4.99128C5.67964 5.06749 5.64154 5.10559 5.60965 5.15013C5.49631 5.30842 5.45717 5.53793 5.51165 5.72483C5.52698 5.77742 5.54694 5.81899 5.58685 5.90211Z"
                            stroke="#333B4A"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <Link href={`tel:${item?.mobile}`} target="_blank">
                          <p className="dfsf text-xs font-medium">
                            {item?.mobile || "-"}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ))
                : "-"}

              <div className="flex items-center py-2 gap-2">
                <div className="">
                  <svg
                    width="16"
                    height="16"
                    className=""
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99984 8.33337C9.10441 8.33337 9.99984 7.43794 9.99984 6.33337C9.99984 5.2288 9.10441 4.33337 7.99984 4.33337C6.89527 4.33337 5.99984 5.2288 5.99984 6.33337C5.99984 7.43794 6.89527 8.33337 7.99984 8.33337Z"
                      stroke="#333B4A"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.99984 14.6667C9.33317 12 13.3332 10.2789 13.3332 6.66671C13.3332 3.72119 10.9454 1.33337 7.99984 1.33337C5.05432 1.33337 2.6665 3.72119 2.6665 6.66671C2.6665 10.2789 6.6665 12 7.99984 14.6667Z"
                      stroke="#333B4A"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="dfsf text-xs font-medium">{`${
                  contactData?.address_1 ? `${contactData?.address_1},` : ""
                } ${
                  contactData?.address_2 ? `${contactData?.address_2},` : ""
                } ${
                  contactData?.city_name ? `${contactData?.city_name},` : ""
                } ${
                  contactData?.region_name ? `${contactData?.region_name},` : ""
                } ${
                  contactData?.country_name
                    ? `${contactData?.country_name},`
                    : ""
                }  ${
                  contactData?.zip_code ? `${contactData?.zip_code}` : ""
                }`}</p>
              </div>
              <div className="flex items-center py-2 gap-2">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_3618_18396)">
                    <path
                      d="M3.3335 9.52428C2.09925 10.0688 1.3335 10.8275 1.3335 11.6667C1.3335 13.3236 4.31826 14.6667 8.00016 14.6667C11.6821 14.6667 14.6668 13.3236 14.6668 11.6667C14.6668 10.8275 13.9011 10.0688 12.6668 9.52428M12.0002 5.33337C12.0002 8.04251 9.00016 9.33337 8.00016 11.3334C7.00016 9.33337 4.00016 8.04251 4.00016 5.33337C4.00016 3.12423 5.79102 1.33337 8.00016 1.33337C10.2093 1.33337 12.0002 3.12423 12.0002 5.33337ZM8.66683 5.33337C8.66683 5.70156 8.36835 6.00004 8.00016 6.00004C7.63197 6.00004 7.3335 5.70156 7.3335 5.33337C7.3335 4.96518 7.63197 4.66671 8.00016 4.66671C8.36835 4.66671 8.66683 4.96518 8.66683 5.33337Z"
                      stroke="#333B4A"
                      strokeWidth="1.33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3618_18396">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {contactData?.google_map_link ? (
                  <Link
                    className="dfsf text-xs font-medium"
                    href={
                      contactData?.google_map_link.startsWith("http")
                        ? contactData?.google_map_link
                        : `http://${contactData?.google_map_link}`
                    }
                    target="google"
                  >
                    {contactData?.google_map_link
                      ? `${contactData?.google_map_link}`
                      : "-"}
                  </Link>
                ) : null}
              </div>
              <div className="flex items-center py-2 gap-2">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1804_42620)">
                    <path
                      d="M8.00016 4.00004V8.00004L10.6668 9.33337M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004Z"
                      stroke="#333B4A"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1804_42620">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="dfsf text-xs font-medium">
                  {contactData?.opening_days && contactData?.opening_hours
                    ? `${contactData?.opening_days} [ ${contactData?.opening_hours} ]`
                    : "-"}
                </p>
              </div>
              <div className="flex items-center py-2 gap-2">
                <svg
                  width="16"
                  height="16"
                  className=""
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.44101 2.2353C7.74991 1.92157 8.25074 1.92157 8.55964 2.2353L10.2376 3.9396C10.5465 4.25333 10.5465 4.76203 10.2376 5.07576L8.55964 6.78006C8.25074 7.09379 7.74991 7.09379 7.44101 6.78006L5.76308 5.07576C5.45418 4.76203 5.45418 4.25333 5.76308 3.9396L7.44101 2.2353ZM3.24618 9.3365C4.01843 10.1209 5.27049 10.1209 6.04273 9.3365C6.81498 8.55212 6.81498 7.28042 6.04273 6.49604C5.27049 5.71166 4.01843 5.71166 3.24618 6.49604C2.47393 7.28042 2.47393 8.55212 3.24618 9.3365ZM9.95792 9.3365C10.7302 10.1209 11.9822 10.1209 12.7545 9.3365C13.5267 8.55212 13.5267 7.28042 12.7545 6.49604C11.9822 5.71166 10.7302 5.71166 9.95792 6.49604C9.18567 7.28042 9.18567 8.55212 9.95792 9.3365ZM9.3986 9.90459C8.62636 9.1202 7.37429 9.1202 6.60205 9.90459C5.8298 10.689 5.8298 11.9607 6.60205 12.745C7.37429 13.5294 8.62636 13.5294 9.3986 12.745C10.1709 11.9607 10.1709 10.689 9.3986 9.90459Z"
                    fill="#333B4A"
                  />
                </svg>
                <Link href={`https://mor.link/${id}`} target="_blank">
                  <p className="dfsf text-xs font-medium">
                    {process.env.BASE_URL}
                    {id}
                  </p>
                </Link>
              </div>
            </div>
            <div className="w-32 h-1 border-t border-black-color-200 mx-auto mt-5"></div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none py-4 justify-end">
          <Button
            color="gray"
            className="w-full rounded"
            onClick={handleCSVDownload}
          >
            {language == "ar" ? "احفظ في جهات الاتصال" : "Save to contacts"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PreviewContactDetailsModal;
